<template>
  <b-sidebar id="add-new-site-sidebar"
             :visible="isAddNewSiteSidebarActive"
             bg-variant="white"
             sidebar-class="sidebar-lg"
             shadow
             backdrop
             no-header
             right
             @change="(val) => $emit('update:is-add-new-site-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Site" : actionType == 1 ? " Update Site" : "Delete Site" }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)">

          <validation-provider #default="validationContext"
                               name="Name"
                               rules="required">
            <b-form-group label="Name"
                          label-for="full-name">
              <b-form-input id="full-name"
                            v-model="currentSite.name" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :disabled="actionType == 2"
                            placeholder="Name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group v-if="actionType != 2"
                        label="Production Location Type"
                        label-for="production-location-type-id">
            <production-location-type-picker :initialProductionLocationTypes="currentSite.productionLocationTypeID"
                            @selectionChanged="onProductionLocationTypeSelectionChanged"
                            ref="productionLocationTypePickerComponent">
            </production-location-type-picker>
          </b-form-group>

          <b-form-group v-if="actionType != 2"
                        label="Company"
                        label-for="company-id">
            <company-picker :initialCompanies="currentSite.companyID"
                            @selectionChanged="onCompanySelectionChanged"
                            ref="companyPickerComponent">
            </company-picker>
          </b-form-group>

          <b-form-group v-if="actionType != 2"
                        label="Country"
                        label-for="site-id">
            <country-picker :initialCountries="currentSite.countryID"
                            @selectionChanged="onCountrySelectionChanged"
                            ref="countryPickerComponent">
            </country-picker>
          </b-form-group>

          <validation-provider
                               name="Is Active">
            <b-form-group label=""
                          label-for="isActive">
              <b-form-checkbox class="mt-2"
                               :disabled="actionType == 2"
                               id="isActive"
                               v-model="currentSite.isActive">
                Is Active
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                      v-if="currentSite == null || currentSite.id == null">
              Add
            </b-button>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="updatesite"
                      v-if="currentSite != null && currentSite.id != null && actionType == 1">
              Update
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                      v-if="currentSite != null && currentSite.id != null && actionType == 2"
                      variant="outline-danger"
                      @click="deletesite">
              Delete
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import CompanyPicker from '../components/CompanyPicker.vue'
import ProductionLocationTypePicker from '../components/ProductionLocationTypePicker.vue'
import CountryPicker from '../components/CountryPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    CompanyPicker,
    CountryPicker,
    ProductionLocationTypePicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSiteSidebarActive',
    event: 'update:is-add-new-site-sidebar-active',
  },
  props: {
    isAddNewSiteSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onCompanySelectionChanged(item) {
      this.currentSite.companyID = (item != null && item.id != null) ? item.id : 0;
    },
    onCountrySelectionChanged(item) {
      this.currentSite.countryID = (item != null && item.id != null) ? item.id : 0;
    },
    onProductionLocationTypeSelectionChanged(item) {
      this.currentSite.productionLocationTypeID = (item != null && item.id != null) ? item.id : 0;
    },
    deletesite() {
      this.$store.dispatch('apps-site/deleteSite', this.currentSite)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-site-sidebar-active', false)
          this.notification()
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Site Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    updatesite() {
      if (this.currentSite.companyID == null || this.currentSite.companyID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Company Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentSite.countryID == null || this.currentSite.countryID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Country Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentSite.productionLocationTypeID == null || this.currentSite.productionLocationTypeID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Production Location Type Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else {
        this.$store.dispatch('apps-site/updateSite', this.currentSite)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-site-sidebar-active', false)
            // this.$emit('show-success-toast')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Site Successfully Updated',
                icon: 'BellIcon',
              },
            })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Site Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
    },
    submitForm() {
      if (this.currentSite.companyID == null || this.currentSite.companyID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Company Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentSite.countryID == null || this.currentSite.countryID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Country Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentSite.productionLocationTypeID == null || this.currentSite.productionLocationTypeID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Production Location Type Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else {
        this.$store.dispatch('apps-site/createSite', this.currentSite)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-site-sidebar-active', false)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Create Site Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },

  watch: {
    sitedata: {
      handler: function (n, o) {
        if (n == null || n == undefined) {
          this.currentSite = {
            id: null,
            name: null,
            productionLocationTypeID: null,
            companyID: null,
            countryID: null,
            isFinal: null,
            isActive: false,
          }
        } else {
          this.currentSite = n.selectedSite;
        }
      },
      deep: true
    }

  },
  data() {
    return {
      required,
      currentSite: null
    }
  },
  inject: ['sitedata'],
  setup(props, { emit }) {
    const blankSiteData = {
      id: null,
      name: null,
      siteID: null,
      productionLocationTypeID: null,
      parentID: null,
      isFinal: false,
      isActive: false,
    }
    const siteData = ref(JSON.parse(JSON.stringify(blankSiteData)))
    const {
      getValidationState,
    } = formValidation(siteData)

    return {
      siteData,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-site-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
