<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <v-select v-if="countries != null"
                  v-model="selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="countries"
                  class="select-size-lg"
                  single>
          <template #option="{ id, name }">
            <span> {{ name }}</span><br />
            <small class="text-muted"> {{ id }} </small>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created() {
    this.fetchCountries();
  },
  props: {
    initialCountries: []
  },
  methods: {
    fetchCountries() {
      return store.dispatch('apps-site/fetchCountries', {
        })
        .then(response => {
          var allCountries = response.data;

        store.dispatch('apps-site/fetchAllCountries', {
        })
        .then(response2 => {
          response2.data = response2.data.filter(x => !x.isDelete);

          response2.data.forEach(item => {
            var country = allCountries.find(x => x.id == item.externalID);

            if (country != null) {
              item.name = country.name;
            }
          });

          this.countries = response2.data;
          this.selected = this.initialCountries != null && response2.data.find(x => x.id == this.initialCountries) != null ? response2.data.find(x => x.id == this.initialCountries) : null;
        })

        })
        .catch(error => reject(error))
    },
  },
  watch: {
    selected: function (newVal, coldVal) {
      this.$emit("selectionChanged", newVal);
    },
    initialCountries: function (newVal, coldVal) {
      if (this.countries.find(x => x.id == newVal) != null)
        this.selected = this.countries.find(x => x.id == newVal);
      else
        this.selected = null
    }
  },
  data() {
    return {
      countries: null,
      allCountries: null,
      selected: null,
    }
  }
}
</script>