import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSiteList() {
  // Use toast
  const toast = useToast()


  // Table Handlers
  const tableColumns = [
    { key: 'id', label: "ID", sortable: true },
    { key: 'name', label: "Name", sortable: true },
    { key: 'productionLocationTypeName', label: "Production Location Type", sortable: true },
    { key: 'companyName', label: "Company", sortable: true },
    { key: 'countryName', label: "Country", sortable: true },
    { key: 'isActive', label: "is Active", sortable: true },
    { key: 'actions' },
  ]
  const totalSites = ref(0)
  const tableItems = ref([])
  const refSiteListTable = ref(null)
  const countries = ref([])
  const isSortDirDesc = ref(true)


  function GetSites(){
    store
      .dispatch('apps-site/fetchAllSites', {
      })
      .then(response => {
        response.data = response.data.filter(x => !x.isDelete);
        store
          .dispatch('apps-site/fetchAllProductionLocationTypes', {
          })
          .then(response2 => {
            response2.data = response2.data.filter(x => !x.isDelete);
            response.data.forEach(item => {
              item.productionLocationTypeName = '';
              if (item.productionLocationTypeID != 0 && response2.data.find(x => x.id == item.productionLocationTypeID) != null) {
                item.productionLocationTypeName = response2.data.find(x => x.id == item.productionLocationTypeID).name;
              }
            });
            store
              .dispatch('apps-site/fetchAllCountries', {
              })
              .then(response3 => {
                response3.data = response3.data.filter(x => !x.isDelete);
                response.data.forEach(item => {
                  item.countryName = '';
                  if (item.countryID != 0 && response3.data.find(x => x.id == item.countryID) != null) {
                    var externalId = response3.data.find(x => x.id == item.countryID).externalID;
                    var country = countries.value.find(x => x.id == externalId);

                    if(country != null){
                      item.countryName = country.name;
                    }
                  }
                });
                store
                  .dispatch('apps-site/fetchAllCompanies', {
                  })
                  .then(response4 => {
                    response4.data = response4.data.filter(x => !x.isDelete);
                    response.data.forEach(item => {
                      item.companyName = '';
                      if (item.companyID != 0 && response4.data.find(x => x.id == item.companyID) != null) {
                        item.companyName = response4.data.find(x => x.id == item.companyID).name;
                      }
                    });
                    tableItems.value = response.data;
                    totalSites.value = response.data.length;
                  })
                  .catch(error => reject(error));
              })
              .catch(error => reject(error));
          })
          .catch(error => reject(error));
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching site list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  function GetAllCountries() {
    var companyResult = [];

    return new Promise((resolve, reject) => {
      store.dispatch('apps-site/fetchCountries')
      .then(response => {
        if(response.data != null && response.data.length > 0){
          companyResult = response.data;
          countries.value = companyResult;
          resolve(companyResult);
          
        } else {
          reject(companyResult)
        }
      })
      .catch(() => {
        reject(companyResult)
      })
    })
  }

  const refetchData = () => {
    GetData();
  }

  async function GetData(){
    await GetAllCountries();
    GetSites();
  }

  GetData()


  return {
    refetchData,
    tableColumns,
    tableItems,
    totalSite: totalSites,
    refSiteListTable,
    isSortDirDesc,
  }
}
