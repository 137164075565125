import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllSites(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Site')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAllCountries(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Country')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAllCompanies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Company')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAllProductionLocationTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/ProductionLocationType')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createSite(ctx, siteData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/Site/Create', siteData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateSite(ctx, siteData) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/organization/Site/Update', siteData, { params: { id: siteData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteSite(ctx, siteData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/organization/Site/Delete', { params: { id: siteData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries() {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Country/ExternalCountries')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
